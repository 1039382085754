import React from "react";
import styled from "styled-components";

import { MainTemplate } from "../templates/mainTemplate";
import { Seo } from "../components/Seo";

const AboutStyled = styled.section``;

const About = () => {
  return (
    <>
      <Seo title="WDN | About" />
      <MainTemplate>
        <AboutStyled>
          <h2>About</h2>
          <p>
            This is a project to help people find the best place to eat in the
            city.
          </p>
        </AboutStyled>
      </MainTemplate>
    </>
  );
};

export default About;
